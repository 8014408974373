import styled from "styled-components";
import { default as LB } from "./LinkBar";

export const LinkBar = LB;

const gravatarUrl = (size) =>
  `https://www.gravatar.com/avatar/5d73b23c9339c246d40bb8af74169bf4?s=${size}`;

export const Wrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
`;

export const Avatar = styled.img`
  border-radius: 50%;
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  content: url(${(props) => gravatarUrl(props.size * 2)});
`;

export const Header = styled.h3`
  font-weight: 800;
  font-size: 30px;
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 10px;
`;
