import React, { Component } from "react";
import { Wrapper, Avatar, Header, Text, LinkBar } from "../components";
import Layout from "../components/Layout";

const InfoSection = () => (
  <Wrapper>
    <LinkBar />
    <Avatar alt="Avatar" size={130} />
    <Header>John Ryan</Header>
    <Text>From NYC.</Text>
    <Text>
      Building @{" "}
      <a target="_blank" rel="noopener noreferrer" href="https://notion.so">
        Notion
      </a>
    </Text>
    <Text>
      Founder @{" "}
      <a target="_blank" rel="noopener noreferrer" href="https://truly.co">
        Truly
      </a>
    </Text>
    <Text>
      I enjoy taking things apart and (sometimes) putting them back together
    </Text>
    <Header>Get In Touch!</Header>
    {/* <form name="contact" action="thank-you" netlify>
    <p>
      <label>Your Name: <input type="text" name="name"/></label>
    </p>
    <p>
      <label>Your Email: <input type="email" name="email"/></label>
    </p>
    <p>
      <label>Message: <textarea name="message"></textarea></label>
    </p>
    <p>
      <button type="submit">Send</button>
    </p>
  </form> */}
  </Wrapper>
);

class IndexPage extends Component {
  render() {
    return (
      <Layout>
        <InfoSection />
      </Layout>
    );
  }
}

export default IndexPage;
