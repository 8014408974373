import React from "react";
import styled from "styled-components";

const Description = styled.span`
  opacity: 0;
  position: static;
  width: 150px;
  margin: 5px;
  font-size: 12px;
  transition: all 0.2s ease;
`;

const Wrapper = styled.a`
  display: flex;
  margin: 10px;
  padding-top: 10px;
  width: 60px;
  text-decoration: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  &:hover ${Description} {
    opacity: 1;
  }
  &:visited {
    color: #222;
  }
  &:hover {
    img {
      transform: scale(0.85);
    }
  }
  img {
    transition: all 0.2s ease;
    transform: scale(0.7);
  }
`;

const LinkButton = props => {
  return (
    <Wrapper href={props.url} rel="noopener noreferrer" target="_blank">
      <img src={require(`./assets/${props.imageUrl}.svg`)} alt={props.name} />
      <Description>{props.description}</Description>
    </Wrapper>
  );
};

const LinkBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export default function LinkBar(props) {
  return (
    <LinkBarContainer>
      <LinkButton
        name="SoundCloud"
        description="Songs!"
        imageUrl="soundcloud"
        url="https://soundcloud.com/johnryannyc"
      />
      <LinkButton
        name="Stack Overflow"
        description="Answers!"
        imageUrl="stack-overflow"
        url="https://stackoverflow.com/users/2908476/john-ryan"
      />
      <LinkButton
        name="Github"
        description="Code!"
        imageUrl="github"
        url="https://github.com/johnryan"
      />
      <LinkButton
        name="Medium"
        description="Words!"
        imageUrl="medium"
        url="https://medium.com/@johnryan1"
      />
      <LinkButton
        name="LinkedIn"
        description="Work!"
        imageUrl="linkedin"
        url="https://www.linkedin.com/in/john-ryan-85983718/"
      />
      <LinkButton
        name="Instagram"
        description="Photos!"
        imageUrl="instagram"
        url="https://www.instagram.com/johnryan1/"
      />
      <LinkButton
        name="Twitter"
        description="Opinions!"
        imageUrl="twitter"
        url="https://twitter.com/john_ryan_nyc"
      />
    </LinkBarContainer>
  );
}
